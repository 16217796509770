import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Home.css'; // Import the CSS file for styling
import { supportedLanguages } from '../Constants';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState('');
  const [roomId, setRoomId] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(supportedLanguages[0]);

  useEffect(() => {
    if (location.state?.roomId) {
      setRoomId(location.state.roomId);
    }
    // Track pageview for Google Analytics
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      page_title: 'Home_page',
    });
  }, [location.state]);

  const createRoom = () => {
    if (playerName.trim()) {
      const newRoomId = Math.random().toString(36).substring(2, 7);
      navigate(`/game/${newRoomId}`, { state: { playerName, selectedLanguage } });
      // Track the event
      window.gtag('event', 'create_room', {
        event_category: 'Game Room',
        event_label: 'Room Created',
        value: newRoomId,
      });
    }
  };

  const joinRoom = () => {
    if (playerName.trim() && roomId.trim()) {
      navigate(`/game/${roomId}`, { state: { playerName, selectedLanguage } });
      // Track the event
      window.gtag('event', 'join_room', {
        event_category: 'Game Room',
        event_label: 'Room Joined',
        value: roomId,
      });
    }
  };

  return (
    <div className="home">

      <h1>KBC Quiz</h1>
      <h3>Fast Minds, Fierce Games!</h3>
      <div>
          <label>
          Your quiz language: 
        <select
          value={selectedLanguage}
          onChange={e => setSelectedLanguage(e.target.value)}
        >
          <option value={supportedLanguages[0]}>English</option>
          {/* <option value={supportedLanguages[1]}>Hindi</option> */}
        </select>
      </label>
          </div>
      <input
        type="text"
        placeholder="Enter your name"
        value={playerName}
        onChange={(e) => setPlayerName(e.target.value)}
      />
      <button onClick={createRoom} disabled={roomId.trim() !== ''}>Create Game Room</button>
      <input
        type="text"
        placeholder="Enter Room ID"
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      />
      <button onClick={joinRoom}>Join Game Room</button>

      <div className="how-to-play">
        <h2>How to Play:</h2>
        <ul>
          <li>Enter your name in the text field below.</li>
          <li>To start a new game, click "Create Game Room". Share the Room ID with your friends so they can join.You can also play single player by starting the game!</li>
          <li>If you've received a Room ID, enter it in the "Enter Room ID" field and click "Join Game Room".</li>
          <li>Answer the questions as quickly as you can to earn points and climb the leaderboard.</li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
