import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import GameRoom from './components/GameRoom';
//import AdComponent from './components/AdComponent';
import { io } from 'socket.io-client';

// Get the socket URL based on the environment
const socketUrl = process.env.REACT_APP_SOCKET_URL;

const socket = io(socketUrl);

// Wrapper component to conditionally render ads
const AppContent = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      {children}
      {/* {!isHomePage && <AdComponent />} */}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/game/:roomId" element={<GameRoom socket={socket} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AppContent>
    </Router>
  );
};

export default App;
