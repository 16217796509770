import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
  EmailShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
  TelegramIcon,
  FacebookMessengerIcon,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeHigh, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import {supportedLanguages} from '../Constants'
import CapsuleWidget from './capsule/CapsuleWidget'

const backgroundMusicUrl = 'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3';

//const categories = ['General Knowledge','History', 'Geography', 'Science', 'Sports', 'Bollywood', 'Hollywood', 'Indian Cricket'];
const categories = [
  { name: 'General Knowledge', icon: '/icons/knowledge.png' },
  { name: 'History', icon: '/icons/parchment.png' },
  { name: 'Geography', icon: '/icons/mountain.png' },
  { name: 'Science', icon: '/icons/atom.png' },
  { name: 'Sports', icon: '/icons/sports.png' },
  { name: 'Bollywood', icon: '/icons/bollywood.png' },
  { name: 'Hollywood', icon: '/icons/red-carpet.png' },
  { name: 'Indian Cricket', icon: '/icons/cricket-helmet.png' },
  { name: 'Harry Potter', icon: '/icons/harry-potter.png'},
  { name: 'Mythology', icon: '/icons/mythology.png', new: true },
  { name: 'Hindu Mythology', icon: '/icons/hindu-mythology.png', new: true },

];
let numOfQuestionsAnsweredPerGame;

// Add styles for audio controls
const styles = {
  volumeControlsDesktop: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  volumeControlsMobile: {
    display: 'none',
  },
  volumeButtonMobile: {
    display: 'none',
  }
};

// Add media queries
if (typeof window !== 'undefined') {
  if (window.matchMedia('(max-width: 767px)').matches) {
    styles.volumeControlsDesktop.display = 'none';
    styles.volumeControlsMobile.display = 'flex';
    styles.volumeButtonMobile.display = 'block';
  }
}

const GameRoom = ({ socket }) => {
  const { roomId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const playerName = location.state?.playerName || '';  // Default to an empty string if playerName is undefined
  const [numQuestions, setNumQuestions] = useState(10);
  const [timePerQuestion, setTimePerQuestion] = useState(30);
  const [gameStarted, setGameStarted] = useState(false);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [scores, setScores] = useState({});
  const [players, setPlayers] = useState({});
  const [shareableLink, setShareableLink] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [remainingTime, setRemainingTime] = useState(timePerQuestion);  // New state variable for remaining time
  const [selectedAnswer, setSelectedAnswer] = useState(null);  // State to store the selected answer
  const [isCorrect, setIsCorrect] = useState(null);  // State to track if the selected answer is correct
  //declarations for popup with scores
  const [showPopup, setShowPopup] = useState(false);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [sortedScores, setSortedScores] = useState([]);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const selectedLanguage = location.state?.selectedLanguage;
  const [isGameStarting, setIsGameStarting] = useState(false);  // Add this new state
  const lastCategoryChangeTime = useRef(0);  // Add this ref for debouncing
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const [musicVolume, setMusicVolume] = useState(0.5); // Default volume 50%
  const [showVolumeControls, setShowVolumeControls] = useState(false);

  const audioRef = useRef(null);

  useEffect(() => {
    // Check if playerName is valid before emitting joinRoom event
    if (playerName.trim() !== '') {
      socket.emit('joinRoom', { roomId, playerName, selectedLanguage });
    } else {
      navigate('/', { state: { roomId } });  // Redirect to the home page if no valid player name is found
    }

    const handleBackPress = () => {
      console.log("backbutton pressed");
      socket.disconnect(); // Disconnect the socket on back button press
      navigate('/');
      socket.connect(); // Redirect to the home page
    };

    window.addEventListener('popstate', handleBackPress); // Add event listener for back button press

    socket.on('playersUpdated', (updatedPlayers) => {
      setPlayers(updatedPlayers);
    });

    socket.on('gameStarted', (settings) => {
      setNumQuestions(settings.numQuestions);
      setTimePerQuestion(settings.timePerQuestion);
      setRemainingTime(settings.timePerQuestion);
      setGameStarted(true);
    });

    socket.on('newQuestion', (newQuestion) => {
      setQuestion(newQuestion);
      setAnswers([]);
      setRemainingTime(timePerQuestion);  // Reset remaining time when a new question is received
      setSelectedAnswer(null);  // Reset selected answer
      setIsCorrect(null);  // Reset correctness
    });

    socket.on('answerSubmitted', (data) => {
      setAnswers((prevAnswers) => [...prevAnswers, data]);
    });

    socket.on('currentScores', (updatedScores) => {
      setScores(updatedScores);
    });

    socket.on('gameEnded', (finalScores) => {
      setScores(finalScores);
      setGameStarted(false);
      // Don't reset selectedCategories here as we want to maintain the selection
    });

    socket.on('currentQuestion', ({ question, remainingTime }) => {
      setGameStarted(true);
      setQuestion(question);
      setRemainingTime(remainingTime);  // Set the remaining time received from the server
      setSelectedAnswer(null);  // Reset selected answer
      setIsCorrect(null);  // Reset correctness
    });

    socket.on('showScoresAndAnswer', ({ correctAnswerIndex, sortedScores }) => {
      setCorrectAnswerIndex(correctAnswerIndex);
      setSortedScores(sortedScores);
      setShowPopup(true);

      // Automatically hide the popup after 5 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    });

    return () => {
      socket.off('playersUpdated');
      socket.off('gameStarted');
      socket.off('newQuestion');
      socket.off('answerSubmitted');
      socket.off('gameEnded');
      socket.off('currentScores');
      socket.off('showScoresAndAnswer');
    };
  }, [roomId, socket, playerName, navigate, timePerQuestion, selectedLanguage]);

  useEffect(() => {
    const link = `${window.location.origin}/game/${roomId}`;
    setShareableLink(link);
  }, [roomId]);

  useEffect(() => {
    // Track pageview for Google Analytics.
    // Room page is visited if room id changes or if game has ended.
    if(gameStarted == false) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: 'Room_page',
      });
    }
    numOfQuestionsAnsweredPerGame = 0;
  }, [roomId, gameStarted]);

  // Countdown timer effect
  useEffect(() => {
    if (gameStarted && question) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [gameStarted, question]);

  // Remove the logging effect for selectedCategories
  const handleCategoryChange = (category) => {
    // Add debouncing to prevent rapid consecutive clicks
    const now = Date.now();
    if (now - lastCategoryChangeTime.current < 300) { // 300ms debounce
      return;
    }
    lastCategoryChangeTime.current = now;

    setSelectedCategories((prevSelected) => {
      const newSelected = prevSelected.includes(category)
        ? prevSelected.filter((cat) => cat !== category)
        : [...prevSelected, category];
      return newSelected;
    });
  };

  const handleTimePerQuestionChange = (e) => {
    const timeValue = parseInt(e, 10);
    setTimePerQuestion(timeValue);
    setRemainingTime(timeValue);
  };

  const startGame = () => {
    // Prevent multiple game starts
    if (isGameStarting) {
      return;
    }

    setIsGameStarting(true);

    // Ensure selectedCategories is an array
    const categoriesToSend = Array.isArray(selectedCategories) ? selectedCategories : [];

    socket.emit('startGame', roomId, { 
      numQuestions, 
      timePerQuestion, 
      selectedCategories: categoriesToSend,
      selectedLanguage 
    });
    
    setGamesPlayed((prev) => prev + 1);
    
    // Track when a game starts
    window.gtag('event', 'start_game', {
      event_category: 'Game',
      event_label: `Game Started in Room`,
      value: gamesPlayed + 1,
    });
    
    window.gtag('event', 'start_game_players', {
      event_category: 'Game',
      event_label: `Game Started with players number`,
      value: Object.keys(players).length,
    });
    
    // Track each selected category
    categoriesToSend.forEach((category) => {
      window.gtag('event', 'start_game_category', {
        event_category: 'Game',
        event_label: `Game Started with category`,
        value: category,
      });
    });

    // Reset the game starting flag after a short delay
    setTimeout(() => {
      setIsGameStarting(false);
    }, 1000);
  };

  const submitAnswer = (optionIndex) => {
    const option = question.options[optionIndex];
    setSelectedAnswer(option);  // Set the selected answer
    const correctAnswer = question.correctAnswer;
    setIsCorrect(option === correctAnswer);  // Check if the selected answer is correct
    socket.emit('submitAnswer', roomId, optionIndex);

    numOfQuestionsAnsweredPerGame = numOfQuestionsAnsweredPerGame+1;
    window.gtag('event', 'question_answered', {
      event_category: 'Game',
      event_label: `Question answered in Room: ${roomId}`,
      value: numOfQuestionsAnsweredPerGame,
    });
  };

  const renderScores = () => {
    const sortedScores = Object.keys(scores)
      .map((playerId) => ({
        playerId,
        name: players[playerId]?.name,
        ...scores[playerId],
      }))
      .sort((a, b) => b.correct - a.correct || a.time - b.time);
  
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        padding: "5px"
      }}>
        <div style={{
          width: "100%",
          background: "rgba(0, 0, 0, 0.7)",
          borderRadius: "12px",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
        }}>
          {sortedScores.map((player, index) => (
            <div key={player.playerId} style={{
              display: "flex",
              alignItems: "center",
              padding: "12px",
              margin: "8px 0",
              background: index === 0 ? "rgba(255, 204, 0, 0.1)" : "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              transition: "all 0.3s ease"
            }}>
              <div style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                background: index === 0 ? "#ffcc00" : "#ffffff20",
                color: index === 0 ? "#000" : "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontWeight: "bold"
              }}>
                {index + 1}
              </div>
              <div style={{
                flex: 1,
                color: "#fff",
                fontSize: "16px",
                fontWeight: index === 0 ? "bold" : "normal"
              }}>
                {player.name}
              </div>
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "20px"
              }}>
                <div style={{
                  color: "#ffcc00",
                  fontWeight: "bold"
                }}>
                  {player.correct}
                  <span style={{
                    fontSize: "12px",
                    color: "#ffffff80",
                    marginLeft: "4px"
                  }}>
                    correct
                  </span>
                </div>
                <div style={{
                  color: "#fff"
                }}>
                  {player.time.toFixed(1)}
                  <span style={{
                    fontSize: "12px",
                    color: "#ffffff80",
                    marginLeft: "4px"
                  }}>
                    sec
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(shareableLink);
    alert('Game room link copied to clipboard!');
  };

  const getJoinedNames = () => {
    const playerArray = Object.values(players);
    return playerArray.map(player => player.name).join(', ');
  }

  const startGameButtonText = Object.keys(scores).length > 0 ? "Continue..." : "Start Game";

  // Add useEffect to initialize audio settings
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = musicVolume;
    }
  }, []);

  const toggleMusic = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsMusicPlaying(true);
      } else {
        audioRef.current.pause();
        setIsMusicPlaying(false);
      }
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setMusicVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const toggleVolumeControls = () => {
    setShowVolumeControls(!showVolumeControls);
  };

  return (
    <div className="game-room">
      <style>
        {`
          @media (max-width: 767px) {
            .volume-controls-desktop {
              display: none !important;
            }
            .volume-button-mobile {
              display: block !important;
            }
            .volume-controls-mobile {
              display: flex !important;
            }
          }
          @media (min-width: 768px) {
            .volume-controls-desktop {
              display: flex !important;
            }
            .volume-button-mobile {
              display: none !important;
            }
            .volume-controls-mobile {
              display: none !important;
            }
          }
        `}
      </style>
      <audio ref={audioRef} src={backgroundMusicUrl} loop autoPlay>
        Your browser does not support the audio element.
      </audio>
      {!gameStarted ? (
        <>
          <h1>Game Room: {roomId}</h1>
          <div className="config">
            {Object.keys(scores).length > 0 && (
              <>
                <h2>Final Scores:</h2>
                {renderScores()}
              </>
            )}
            <div>
            <h2>Players in the room:</h2>
            <ul>
              {getJoinedNames()}
            </ul>
            </div>
            <div>
            <label>
              Number of Questions:
              <input
                type="number"
                value={numQuestions}
                style={{ width: '60px' }} // Adjust the width as needed
                onChange={(e) => setNumQuestions(e.target.value)}
              />
            </label>
            </div>
            <label>
              Time per Question (seconds):
              <input
                type="number"
                value={timePerQuestion}
                style={{ width: '60px' }} // Adjust the width as needed
                onChange={(e) => handleTimePerQuestionChange(e.target.value)}
              />
            </label>
            <h3>Select Categories:</h3>
            <div className="categories">
              {categories.map((category) => {
                const isSelected = selectedCategories.includes(category.name);
                return (
                  <CapsuleWidget
                    key={category.name}
                    iconSrc={category.icon}
                    text={category.name}
                    isNew={category.new}
                    onClick={() => handleCategoryChange(category.name)}
                    isSelected={isSelected}
                  />
                );
              })}
            </div>
            <div>
              <button 
                onClick={startGame}
                disabled={isGameStarting}
                style={{ opacity: isGameStarting ? 0.7 : 1 }}
              >
                {startGameButtonText}
              </button>
            </div>
            <div className="share-link">
              <h2>Share this game room through:</h2>

              {/* Social media share buttons */}
              <div className="social-share-buttons">
                <WhatsappShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <FacebookMessengerShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>

                <TelegramShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <FacebookShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <LinkedinShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <RedditShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <RedditIcon size={32} round />
                </RedditShareButton>

                <EmailShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
              <h3>Or share this link manually with friends:</h3>
              <input type="text" value={shareableLink} readOnly />
              <button onClick={copyLinkToClipboard}>Copy Link</button>
            </div>
          </div>
        </>
      ) : (
        <div className="game">
          {question && (
            <>
              <div style={{
                display: "flex",
                flexDirection: "column",
                background: "rgba(0, 0, 0, 0.7)",
                borderRadius: "8px",
                margin: "10px 0",
                position: "relative"
              }}>
                {/* Top row with time remaining and volume controls */}
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 20px",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
                }}>
                  <div style={{
                    color: remainingTime <= 5 ? '#ff4444' : '#fff',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    transition: 'color 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}>
                    <FontAwesomeIcon icon={faHourglassHalf} className={remainingTime <= 5 ? 'fa-shake' : ''} />
                    {remainingTime.toFixed(0)} sec
                  </div>
                  <div className="audio-controls" style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                    <button 
                      onClick={toggleMusic}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: '#ffcc00',
                        cursor: 'pointer',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0
                      }}
                    >
                      <FontAwesomeIcon 
                        icon={isMusicPlaying ? faPause : faPlay} 
                        size="lg"
                      />
                    </button>
                    {/* Volume controls for desktop */}
                    <div className="volume-controls-desktop" style={{
                      display: 'none',
                      alignItems: 'center',
                      gap: '10px',
                      '@media (min-width: 768px)': {
                        display: 'flex'
                      }
                    }}>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={musicVolume}
                        onChange={handleVolumeChange}
                        style={{
                          width: '100px',
                          accentColor: '#ffcc00'
                        }}
                      />
                      <span style={{ color: '#fff', fontSize: '14px', minWidth: '40px' }}>
                        {Math.round(musicVolume * 100)}%
                      </span>
                    </div>
                    {/* Volume button for mobile */}
                    <button 
                      onClick={toggleVolumeControls}
                      className="volume-button-mobile"
                      style={{
                        background: 'none',
                        border: 'none',
                        color: '#ffcc00',
                        cursor: 'pointer',
                        padding: 0,
                        display: 'none',
                        '@media (max-width: 767px)': {
                          display: 'block'
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faVolumeHigh} size="lg" />
                    </button>
                  </div>
                </div>
                {/* Bottom row with centered question number */}
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 20px"
                }}>
                  <h2 style={{ 
                    margin: 0,
                    fontSize: '20px',
                    color: '#ffcc00'
                  }}>
                    Question {question.questionIndexInGame} of {numQuestions}
                  </h2>
                </div>
                {/* Vertical volume slider for mobile */}
                {showVolumeControls && (
                  <div className="volume-controls-mobile" style={{
                    position: 'absolute',
                    right: '60px',
                    top: '100%',
                    background: 'rgba(0, 0, 0, 0.9)',
                    padding: '15px 10px',
                    borderRadius: '8px',
                    display: 'none',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    zIndex: 1000,
                    '@media (max-width: 767px)': {
                      display: 'flex'
                    }
                  }}>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={musicVolume}
                      onChange={handleVolumeChange}
                      style={{
                        width: '150px',
                        transform: 'rotate(-90deg)',
                        transformOrigin: 'center',
                        accentColor: '#ffcc00',
                        margin: '40px 0'
                      }}
                    />
                    <span style={{ color: '#fff', fontSize: '14px' }}>
                      {Math.round(musicVolume * 100)}%
                    </span>
                  </div>
                )}
              </div>
              <div className="question-container">
                <h2 className="question-boundary">{question.question}</h2>
              </div>
              {question.type === 'image' && (
                <img src={question.imageUrl} alt="Question" style={{ maxWidth: '100%', height: 'auto' }} />
              )}
              <div className="options-container">
                <div className="options-row">
                  <button
                    onClick={() => submitAnswer(0)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[0]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[0]}
                  </button>
                  <button
                    onClick={() => submitAnswer(1)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[1]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[1]}
                  </button>
                </div>
                <div className="options-row">
                  <button
                    onClick={() => submitAnswer(2)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[2]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[2]}
                  </button>
                  <button
                    onClick={() => submitAnswer(3)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[3]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[3]}
                  </button>
                </div>
              </div>
            </>
          )}
          {Object.keys(scores).length > 0 && <div className="scores" style={{ marginTop: '20px' }}>
            <h2 style={{ marginBottom: '5px' }}>Scores:</h2>
            {renderScores()}
          </div>}
          
        </div>
      )}
      {showPopup && (
        <Popup
          correctAnswer={question.options[correctAnswerIndex]}
          playerScores={sortedScores}
        />
      )}
    </div>
  );
};

export default GameRoom;
