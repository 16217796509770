import React from "react";
import "./Capsule.css"; // Import the updated CSS

const CapsuleWidget = ({ iconSrc, text, onClick, isNew, isSelected }) => {
  // Remove internal state and use the prop directly
  const handleClick = () => {
    onClick(text); // Call the passed function on click, passing the widget text
  };

  return (
    <div
      className={`capsule-widget ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <img src={iconSrc} alt="icon" className="capsule-icon" />
      <span className="capsule-text">{text}</span>
      {isNew && <span className="new-badge">New</span>}
    </div>
  );
};

export default CapsuleWidget;
